@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap');

@import './styles/common.scss';

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $ivory;
    color: $rich-black-fogra-29;
}
