// https://coolors.co/fefdeb-0a191f-f0544f-499167-50808e

/* SCSS HEX */
$ivory: #fefdebff;
$rich-black-fogra-29: #0a191fff;
$tart-orange: #f0544fff;
$middle-green: #499167ff;
$teal-blue: #50808eff;

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-right: linear-gradient(90deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-bottom: linear-gradient(180deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-left: linear-gradient(270deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-top-right: linear-gradient(45deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-bottom-right: linear-gradient(135deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-top-left: linear-gradient(225deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-bottom-left: linear-gradient(315deg, #fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);
$gradient-radial: radial-gradient(#fefdebff, #0a191fff, #f0544fff, #499167ff, #50808eff);

$sm-breakpoint: 600px;
