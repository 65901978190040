@import '../styles/common.scss';

.image-link {
    display: block;
}

.image-box {
    display: grid;
    place-items: center;
    grid-template-areas: "inner-div";

    > * {
        grid-area: inner-div;
    }

    &:hover, &:active {
         .image-border {
            transform: scale(1.025);

            .main-image {
                $blurValue: 3px;
                filter: blur($blurValue);
                -webkit-filter: blur($blurValue);
            }
         }
    }
}

.image-icon-box {
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > img {
        $imageWidth: 64px;

        width: $imageWidth;
        height: auto;
        fill: #060 !important;

        @media(max-width: $sm-breakpoint) {
            width: $imageWidth * .75;
        }
    }

    > p {
        font-weight: 300;
        font-size: 2em;
        letter-spacing: 2px;
        text-decoration-line: underline;
        text-decoration-style: dotted;
        text-decoration-thickness: 3px;
        margin: .5em 0 0 0;
        text-align: center;

        @media(max-width: $sm-breakpoint) {
            font-size: 1.5em;
        }

        &.dark {
            $color: $rich-black-fogra-29;
            color: $color;
            text-decoration-color: $color;
        }

        &.light {
            $color: white;
            color: $color;
            text-decoration-color: $color;
        }
    }

    background-color: rgba(51, 51,51, 0);

    > * {
        opacity: 0;
    }

    &, > * {
        transition: .3s;
    }

    &:hover, &:active {
        // background-color: rgba(51, 51,51, .4);

        > * {
            opacity: 1;
        }
    }
}

.image-border {
    $transitionSpeed: .3s;

    z-index: 50;
    display: block;
    max-width: 100%;
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    transition: $transitionSpeed;

    .main-image {
        max-width: 100%;
        height: auto;
        transition: $transitionSpeed;
    }
}
