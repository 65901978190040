@import '../styles/common.scss';

$siteTitleSize: 7em;
$siteTitleSmallerSize: 4em;

.site-header {
    text-align: center;
    padding: 4em 0 0 0;
    margin-bottom: 5em;

    h1 {
        margin: 0 0 .5rem 0;
        font-size: $siteTitleSize;
        font-weight: 100;

        @media (max-width: $sm-breakpoint) {
            font-size: $siteTitleSmallerSize;
        }
    }

    small {
        font-size: 1.25em;
        font-weight: 300;

        @media (max-width: $sm-breakpoint) {
            font-size: 1em;
        }
    }

    hr {
        margin-top: 4em;
    }
}

.brand-image {
    max-width: $siteTitleSize * 1.5;
    height: auto;

    @media (max-width: $sm-breakpoint) {
        max-width: $siteTitleSmallerSize * 1.5;
    }
}