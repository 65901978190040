@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap);
body{margin:0;font-family:'Lato', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#fefdeb;color:#0a191f}

.App{min-height:100vh;background:#fefdeb;padding:0 4vw}hr{height:1px;margin:1rem 0 0 0;color:inherit;background-color:currentColor;border:0;opacity:.25}a:not([class="no-link"]),a:not([class="no-link"]):visited{color:inherit}a:not([class="no-link"]),a:not([class="no-link"]):hover,a:not([class="no-link"]):active,a:not([class="no-link"]):visited{text-decoration-thickness:1.5px;-webkit-text-decoration-style:dotted;text-decoration-style:dotted;-webkit-text-decoration-line:underline;text-decoration-line:underline;transition:color .3s}a:not([class="no-link"]):hover,a:not([class="no-link"]):active{color:#f0544f !important}a.no-link,a.no-link:hover,a.no-link:active,a.no-link:visited{color:inherit !important;text-decoration:none !important;transition:0 !important}.disable-select{-webkit-user-select:none;-ms-user-select:none;user-select:none}

.site-header{text-align:center;padding:4em 0 0 0;margin-bottom:5em}.site-header h1{margin:0 0 .5rem 0;font-size:7em;font-weight:100}@media (max-width: 600px){.site-header h1{font-size:4em}}.site-header small{font-size:1.25em;font-weight:300}@media (max-width: 600px){.site-header small{font-size:1em}}.site-header hr{margin-top:4em}.brand-image{max-width:10.5em;height:auto}@media (max-width: 600px){.brand-image{max-width:6em}}

.image-link{display:block}.image-box{display:grid;place-items:center;grid-template-areas:"inner-div"}.image-box>*{grid-area:inner-div}.image-box:hover .image-border,.image-box:active .image-border{-webkit-transform:scale(1.025);transform:scale(1.025)}.image-box:hover .image-border .main-image,.image-box:active .image-border .main-image{filter:blur(3px);-webkit-filter:blur(3px)}.image-icon-box{z-index:100;width:100%;height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;background-color:rgba(51,51,51,0)}.image-icon-box>img{width:64px;height:auto;fill:#060 !important}@media (max-width: 600px){.image-icon-box>img{width:48px}}.image-icon-box>p{font-weight:300;font-size:2em;letter-spacing:2px;-webkit-text-decoration-line:underline;text-decoration-line:underline;-webkit-text-decoration-style:dotted;text-decoration-style:dotted;text-decoration-thickness:3px;margin:.5em 0 0 0;text-align:center}@media (max-width: 600px){.image-icon-box>p{font-size:1.5em}}.image-icon-box>p.dark{color:#0a191f;-webkit-text-decoration-color:#0a191f;text-decoration-color:#0a191f}.image-icon-box>p.light{color:#fff;-webkit-text-decoration-color:#fff;text-decoration-color:#fff}.image-icon-box>*{opacity:0}.image-icon-box,.image-icon-box>*{transition:.3s}.image-icon-box:hover>*,.image-icon-box:active>*{opacity:1}.image-border{z-index:50;display:block;max-width:100%;padding:.25rem;background-color:#fff;border:1px solid #dee2e6;border-radius:.25rem;transition:.3s}.image-border .main-image{max-width:100%;height:auto;transition:.3s}

.site-footer{display:flex;align-items:center;flex-direction:column;margin-top:3em}.site-footer hr{width:5%}.site-footer p{margin:0;padding:1.5em 0 1em 0;font-weight:300;font-size:1.25em}

